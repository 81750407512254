<template>
  <div class="c-app flex-row align-items-center" :class="{ 'c-dark-theme': $store.state.darkMode }">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm>
                  <!-- <h1>Login</h1>
                  <p class="text-muted">Sign In to your account</p> -->
                  <CCol col="12" class="mb-5 px-4">
                    <img :src="require('@/assets/images/login/logo.png')" style="width: 100%" />
                  </CCol>
                  <CInput
                    placeholder="아이디 입력"
                    autocomplete="username email"
                    v-model="id"
                  >
                    <template #prepend-content><CIcon name="cil-user"/></template>
                  </CInput>
                  <CInput
                    placeholder="비밀번호 입력"
                    type="password"
                    autocomplete="curent-password"
                    v-model="password"
                  >
                    <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                  </CInput>
                  <CRow>
                    <CCol col="12" class="text-center">
                      <CButton color="primary" class="px-3" style="width: 100%"
                        @click="login()"
                      >로그인</CButton>
                    </CCol>
                    <CCol col="7" class="text-left">
                      <CButton color="link" class="px-0" @click="regist()">회원가입</CButton>
                      <CButton color="link" @click="findId()">비밀번호 찾기</CButton>
                    </CCol>
                    <CCol col="5" class="text-right" style="display: flex; align-items: center; justify-content: flex-end;">
                      <CButton color="link" class="px-0">자동 로그인</CButton>
                      <input type="checkbox">
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
            <!-- <CCard
              color="primary"
              text-color="white"
              class="text-center py-5 d-md-down-none"
              body-wrapper
            >
              <CCardBody>
                <h2>Sign up</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                <CButton
                  color="light"
                  variant="outline"
                  size="lg"
                >
                  Register Now!
                </CButton>
              </CCardBody>
            </CCard> -->
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import axios from 'axios';
import popupTools from 'popup-tools';

export default {
  name: 'c_login',
  data() {
    return {
      id: '',
      password: ''
    }
  },
  methods: {
    regist() {
      this.$router.push({name: 'c_regist', params: {test: 'test'}});
    },
    login() {
        const asyncLogin = async (id, password) => {
            console.log('로그인 진행 : ', id, password);

            try {
                const result = await axios.get('https://suriyo.api.togethersoft.co.kr/items/user?fields=*.*&filter={"id":"' + id + '","password":"' + password + '"}&meta=filter_count')
                console.log('결과값 : ', result);
            
                return result
            }
            catch(err) {
                console.log('query 오류 : ', err);
            }
        }

        // 로그인 진행
        console.log('id', this.id, 'password', this.password);
        console.log('1',this.id,'2');
        // 아이디 및 비밀번호 미입력
        if (this.id == '' || this.password == '') {
            alert('아이디 및 비밀번호를 확인해주세요');
        } else {
          const func = async () => {
            // 로그인 진행
            const result_asyncLogin = await asyncLogin(this.id, this.password);
            // 비정상 통신이거나 쿼리된 유저가 1명이 아닐때
            if (result_asyncLogin.status != 200) {
                alert('잠시후 다시 시도해주세요');
            } else if (result_asyncLogin.data.meta.filter_count != 1) {
                alert('회원 정보가 일치하지 않습니다');
            } else {
                // 정상 로그인
                // 로컬스토리지 상태 저장
                localStorage.setItem('userIndex', result_asyncLogin.data.data[0].index);
                localStorage.setItem('partnerIndex', result_asyncLogin.data.data[0].partner_index[0].index);
                localStorage.setItem('partnerIndexCompanyName', result_asyncLogin.data.data[0].partner_index[0].company_name);
                // setRecoilUserIndex(result_asyncLogin.data.data[0].index);
                // setRecoilUserDateCreated(result_asyncLogin.data.data[0].date_created);
                // setRecoilUserId(result_asyncLogin.data.data[0].id);
                // setRecoilUserPassword(result_asyncLogin.data.data[0].password);
                // setRecoilUserRegistType(result_asyncLogin.data.data[0].regist_type);
                // setRecoilUserPhone(result_asyncLogin.data.data[0].phone);
                // setRecoilUserDi(result_asyncLogin.data.data[0].di);
                // setRecoilUserNickname(result_asyncLogin.data.data[0].nickname);
                // setRecoilUserChattingNoti(result_asyncLogin.data.data[0].chatting_noti);
                // setRecoilUserCommunityNoti(result_asyncLogin.data.data[0].community_noti);
                // setRecoilUserWorkingHistoryNoti(result_asyncLogin.data.data[0].working_history_noti);
                // setRecoilUserEventNoti(result_asyncLogin.data.data[0].event_noti);
                // setRecoilUserImage(result_asyncLogin.data.data[0].image);

                alert('로그인되었습니다.');
                setTimeout(() => {
                  this.$router.push({name: 'c_home', params: {userIndex: result_asyncLogin.data.data[0].index, partnerIndex: result_asyncLogin.data.data[0].partner_index[0].company_name}});
                }, 500);
            }
          }
          func();
        }
    },
    async findId() {
      const handleRedirectUri = async (json) => {
        // 패스포트 기반 Node 인증 서버 사용시
        console.log('간편로그인 인증', json);
        const result = JSON.parse(json);
        console.log('패스포트 응답 (도메인: passport.auth)...', result); // 응답 도착, 분석 후 로긴 후 페이지로 라우팅 필요

        let email = '';
        let provider = '';
        // 개별 프로바이더
        // if (result.provider == 'kakao') {
        //   email = result._json.kakao_account.email;
        //   provider = result.provider;
        // } else if (result.provider == 'google') {
        //   email = result._json.email;
        //   provider = result.provider;
        // } else if (result.provider == 'facebook') {
        //   email = result._json.email;
        //   provider = result.provider;
        // } else if (result.provider == 'naver') {
        //   email = result._json.email;
        //   provider = result.provider;
        // } else if (result.provider == 'apple') {
        //   email = result.email;
        //   provider = result.provider;
        // }

        // // if가 ''일때는 프로바이더에 통과되지 않았을때여서 진행X
        // if (email != '' && provider != '') {
        //   let check = async () => {
        //     // #으로 프로바이더와 이메일을 구분
        //     let custom_email = `${provider}^${email}`;
        //     console.log(custom_email);

        //     // 아이디 중복 여부 확인 필요
        //     const result_asyncRegistIdCheck = await this.asyncRegistIdCheck(custom_email);
        //     if (result_asyncRegistIdCheck.data.meta.filter_count == 0) {
        //       // 동일한 아이디가 없을때
        //       // 조건 만족후 회원가입 진행
        //       const result_asyncRegist = await this.asyncRegist(custom_email, custom_email, provider);
        //       // 회원가입 진행후 정상 등록되었을때
        //       if (result_asyncRegist.status == 200) {
        //         alert('회원가입이 완료되었습니다');
        //         this.oauthLogin(custom_email, custom_email);
        //       } else {
        //         alert('잠시후 다시 시도해주세요');
        //       }
        //     } else {
        //       // toastRef.current.show('사용할수 없는 아이디입니다');
        //       this.oauthLogin(custom_email, custom_email);
        //     }
        //   }
        //   check();
        // }
      }
      popupTools.popup('https://suriyo.iamport.togethersoft.co.kr/certification', "SURIYO Find ID", {}, async function (err, user) {
      // popupTools.popup('https://jsonplaceholder.typicode.com/posts', "SURIYO Find ID", {}, async function (err, user) {
        if (err) {
          alert(err.message);
        } else {
          // save the returned user in localStorage/cookie
          console.log(user);
          const result = await handleRedirectUri(user);
        }
      })
    },
  }
}
</script>
